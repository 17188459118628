import { getFontColorBasedOnBg, IsTextDark } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/solidgear/helpers';
import { useShoeGuide } from '@hultafors/solidgear/hooks';
import { ShoeGuideCtaFragment } from '@hultafors/solidgear/types';

import { Button } from '../button/button';
import { H2 } from '../H2/H2';

import { ShoeGuideCtaBlockStyled } from './ShoeGuideCtaBlock.styled';

export const ShoeGuideCtaBlock: React.FC<ShoeGuideCtaFragment> = ({
  buttonText,
  backgroundColor,
  small,
  title,
}) => {
  const { toggleGuide } = useShoeGuide();
  const isTextDark = (bgColor: string) => {
    return IsTextDark(
      getFontColorBasedOnBg(bgColor, colors.white, colors.black),
    );
  };

  return (
    <ShoeGuideCtaBlockStyled
      $isTextDark={isTextDark(backgroundColor?.hex || colors.white)}
      $backgroundColor={backgroundColor?.hex || colors.white}
      $small={small}
    >
      <div className="Inner">
        {title && <H2>{title}</H2>}
        {buttonText && (
          <Button onClick={toggleGuide} className="Black" stretch={undefined}>
            {buttonText}
          </Button>
        )}
      </div>
    </ShoeGuideCtaBlockStyled>
  );
};
